import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, LanguageContext } from "ToolboxUtils/web/context/context";
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-startup-popin/modal-startup-popin.styles';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';

const ModalStartUpPopin = props => {
    const theme = useContext(ThemeContext).state;
    const language = useContext(LanguageContext).state;
    
    return (
      <ModalWrapper theme={theme} isMobile={isMobileView()} >
        <div className='modal-container'>
          <div className='container'>
          <button onClick={() => props.onClose()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
             <div className='description-container'>
              <div className='description'>
                <Text path={'modal.startupPopin.body'} />
              </div>
          </div> 
          </div>
        </div>
      </ModalWrapper>
    );
  }
  
  ModalStartUpPopin.propTypes = {
    closeModal: PropTypes.func.isRequired,
  };
  
  export default ModalStartUpPopin;
  
