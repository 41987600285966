import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(40, 40, 40, .9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30000000;
  overflow: auto;
  outline: 0;
  .modal-container {
    width: 55%;
    height: 80%;
    margin: ${props => (props.isMobile === true ? '0' : '1.75rem auto')};
    position: relative;
    background: white;
    padding-top : 1px;
    padding-bottom: 1px;
    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid #ccc;
      margin: 1em 0;
      padding: 0;
    }
    .close {
      display: flex;
      color: ${props => { return props.theme.color.primaryColor }};
      opacity: 1;
      position: absolute;
      top: .5rem;
      right: .5rem;
    }
    .container {
      height: 100%;
      .description-container {
        height: 100%;
      }

      .description {
        padding: 20px ;
        font-size: ${props => `${15*props.theme.font.primaryFont.scaling/100}px`};
        margin-bottom: 2rem;
        overflow: auto;
        height: 100%;
      }
      .button {
        display: flex;
        margin-bottom: 1.5rem;
      }
    }
  }
  @media (max-width: 767px) {
    .modal-container {
      width: 90%;
      height: 90%;
      .container {
        .description : {
          font-size: 13px;
        }
      }
    }
  }
`;